import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import DHButtonSave from "../../general/DHButtonSave";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHTextField from "../../general/DHTextField";
import DHButtonNew from "../../general/DHButtonNew";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import ConfirmDialog from "../../general/ConfirmDialog";
import DHSelectionComponent from "../../general/DHSeclectionCompontent";
import DHDatePicker from "../../general/DHDatePicker";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import {TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import DHButtonDefault from "../../general/DHButtonDefault";
import DHDateTimePicker from "../../general/DHDateTimePicker";
import LigaVerwaltungFieldDescription from "./LigaVerwaltungFieldDescription";

export default function LigaRahmenspielplaeneVerwaltenDialog({eventOperatorId, onClose}) {

    const CELL_PADDING_VALUE = 5;

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [working, setWorking] = useState(false);

    const [rahmenspielplanId, setRahmenspielplanId] = useState(undefined);
    const [rahmenspielplanDetailId, setRahmenspielplanDetailId] = useState(undefined);
    const [bezeichnung, setBezeichnung] = useState("");

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [filter, setFilter] = useState("");
    const [rahmenspielplaene, setRahmenspielplaene] = useState([]);
    const [rahmenspielplanDetails, setRahmenspielplanDetails] = useState([]);

    const [spieltermin, setSpieltermin] = useState(undefined);
    const [von, setVon] = useState(undefined);
    const [bis, setBis] = useState(undefined);

    useEffect(() => {
        ladeDatenRahmenspielplaene();
    }, []);

    useEffect(() => {
        ladeDatenRahmenspielplanDetails();
    }, [rahmenspielplanId]);

    const ladeDatenRahmenspielplaene = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/rahmenspielplaene/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setRahmenspielplaene(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const ladeDatenRahmenspielplanDetails = () => {
        if( !rahmenspielplanId ) {
            setRahmenspielplanDetails([]);
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.league + "/rahmenspielplan/details/" + rahmenspielplanId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setRahmenspielplanDetails(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const deleteRahmenspielplan = () => {
        setWorking(true);
        let url = ConfigUtil.getConfig().resourceUrls.league + "/rahmenspielplan/loeschen/" + rahmenspielplanId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Löschen erfolgreich...");
                ladeDatenRahmenspielplaene();
                setShowDeleteDialog(false);
                setRahmenspielplanId(undefined);
                setBezeichnung("");
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
                setShowDeleteDialog(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setWorking(false);
                setShowDeleteDialog(false);
            });
    }

    const saveRahmenspielplan = () => {
        setWorking(true);
        let url = ConfigUtil.getConfig().resourceUrls.league + "/rahmenspielplan/save/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {id: rahmenspielplanId, bezeichnung: bezeichnung},
            json => {
                setRahmenspielplanId(json.id);
                setInfoMessage("Speichern erfolgreich...");
                ladeDatenRahmenspielplaene();
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setWorking(false);
            });
    }

    const deleteRahmenspieltagDetail = () => {
        setWorking(true);
        let url = ConfigUtil.getConfig().resourceUrls.league + "/rahmenspielplan/detail/loeschen/" + rahmenspielplanDetailId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setInfoMessage("Löschen war erfolgreich...");
                setWorking(false);
                setRahmenspielplanDetailId(undefined);
                ladeDatenRahmenspielplanDetails();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
                setRahmenspielplanDetailId(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setWorking(false);
                setRahmenspielplanDetailId(undefined);
            });
    }

    const saveRahmenspielplanDetail = () => {
        setWorking(true);
        let url = ConfigUtil.getConfig().resourceUrls.league + "/rahmenspielplan/detail/save/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {rahmenspielplanId: rahmenspielplanId, von: von, bis: bis, spieltag: 0},
            json => {
                setInfoMessage("Speichern erfolgreich...");
                ladeDatenRahmenspielplanDetails();
                setVon(undefined);
                setBis(undefined);
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setWorking(false);
            });
    }

    const initDialog = () => {
        setRahmenspielplanId(undefined);
        setBezeichnung("");
    }

    const isDisabled = () => {
        return bezeichnung === "";
    }

    const getDHSelectionComponentValue = () => {
        let returnValue = [];
        returnValue.push({name: 'Auswahl löschen', code: undefined});
        for (const tmp of rahmenspielplaene) {
            returnValue.push({name: tmp.bezeichnung, code: tmp.id });
        }
        return returnValue;
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        rahmenspielplanDetails.forEach(detail => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={detail.id}>
                <TableCell style={style}>{detail.spieltag}</TableCell>
                <TableCell style={style}>{detail.von}</TableCell>
                <TableCell style={style}>{detail.bis}</TableCell>
                <TableCell style={style}>
                    <DeleteIcon style={{cursor:"pointer", marginLeft:10}} onClick={() => {
                        setRahmenspielplanDetailId(detail.id);
                    }}/>
                </TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    const getTable = () => {
        return <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
            <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width:50}}>Spieltag</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Von</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Bis</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100,}}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
    }

    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{marginBottom: 20}}>
                Lege einen Rahmenspielplan an, dieser Rahmenspielplan wird dann einer Liga zugewiesen. Der Rahmenspielplan bestimmt z.B. die Wochenenden an denen gespielt wird.<br/>
                Die Auslosung der Liga wird dann die Spieltage innerhalb des Rahmenspielplans vergeben.<br/>
                Du brauchst nicht für jede Liga einen Rahmenspielplan. Sofern z.B. Liga A und Liga C an den gleichen Terminen spielt, ist es ausreichend dafür einen Rahmenspielplan anzulegen.<br/>
                Bitte wähle einen sprechenden Namen, damit du diesen dann den Ligen zuweisen kannst.<br/>
                Beispiel: Rahmenspielplan 2025 - 1. Halbjahr Liga 1 und 3
            </div>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-region-bezeichnung" label="Bezeichnung" width={500} required={true} value={bezeichnung} onChange={(text) => setBezeichnung(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHButtonSave onClick={() => saveRahmenspielplan()} working={working} disabled={isDisabled()}/>,
                <DHButtonNew onClick={() => initDialog()}/>
                <DHButtonDefault label="Löschen" disabled={!rahmenspielplanId}
                                 icon={<DeleteIcon/>} onClick={() => setShowDeleteDialog(true)} style={{marginLeft:5}}/>
            </div>
            <hr/>
            <div>
                <DHSelectionComponent value={rahmenspielplanId} onChange={(id) => {
                    if( id) {
                        setRahmenspielplanId(id);
                        setBezeichnung(rahmenspielplaene.filter(t => t.id === id)[0].bezeichnung);
                    } else {
                        setRahmenspielplanId(undefined);
                        setBezeichnung("");
                    }
                }} values={getDHSelectionComponentValue()} title=""/>
            </div>
            <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
            </div>
            {!rahmenspielplanId  &&
                <div style={{marginTop:20, fontWeight:"bold"}}>
                    Kein Rahmenspielplan ausgewählt.
                </div>
            }
            {rahmenspielplanId && <div style={{marginTop: 20}}>
                <div style={{marginBottom: 10, fontWeight: "bold"}}>Neuen Spieltag anlegen</div>
                <div style={{marginBottom: 20}}>
                    <LigaVerwaltungFieldDescription text="Du musst einen Spieltermin eingeben, dies ist der Standardtermin an dem der Spieltag gespielt wird."/>
                </div>
                <div style={{marginBottom: 20}}><DHDateTimePicker value={spieltermin} changeValue={(value) => setSpieltermin(value)} label={"Spieltermin"}/></div>
                <div style={{marginBottom: 20}}>
                    <LigaVerwaltungFieldDescription text="Von- und Biszeitpunkt kann angegeben werden."/>
                    <LigaVerwaltungFieldDescription text="Sobald z.B. der Standardzeitpunkt ein Samstag ist und an der Mannschaft eingestellt wurde, dass diese immer Fr. um 19 Uhr spielen möchte, wird versucht diesen Wunsch bei der Spielvergabe zu berücksichtigen. Dafür muss aber ein Freitag innerhalb des Von- und Bisdatums liegen."/>
                </div>
                <div style={{marginBottom: 10}}><DHDatePicker value={von} changeValue={(value) => setVon(value)} label={"Von (optional)"}/></div>
                <div style={{marginBottom: 10}}><DHDatePicker value={bis} changeValue={(value) => setBis(value)} label={"Bis (optional)"}/></div>
                <div style={{marginBottom: 10}}><DHButtonSave label="Speichern" disabled={!spieltermin} onClick={() => saveRahmenspielplanDetail()} working={working}/></div>
            </div>}
            {rahmenspielplanId && rahmenspielplanDetails.length === 0 &&
                <div style={{marginTop: 20, fontWeight: "bold"}}>
                    Keine Spieltage vorhanden.
                </div>
            }
            {rahmenspielplanId && rahmenspielplanDetails.length > 0 && <>
                <div style={{marginTop: 20, fontWeight: "bold"}}>
                    Anzahl Spieltage {rahmenspielplanDetails.length}
                </div>
                {getTable()}
            </>
            }
            {rahmenspielplanDetailId && <ConfirmDialog visible={true} header={"Spieltag löschen"}
                                                       textUnten={"Möchtest du den Spieltag wirklich löschen?"} callBackOnNein={() => {
                setRahmenspielplanDetailId(undefined);
            }} callBackOnJa={() => deleteRahmenspieltagDetail()}/>}
            {showDeleteDialog && <ConfirmDialog visible={true} header={"Rahmenspielplan löschen"}
                                                       textUnten={"Möchtest du den Rahmenspielplan wirklich löschen?"} callBackOnNein={() => {
                setShowDeleteDialog(false);
            }} callBackOnJa={() => deleteRahmenspielplan()}/>}
        </div>
    }

    return <DHDialog onClose={() => onClose()} title="Rahmenspielpläne verwalten" show={true} showCloseButton={true}
                     buttons={[]} comp={getDataArea()}/>
}
