import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import {TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";

export default function LigaSpieleDialog({liga, onClose}) {

    const CELL_PADDING_VALUE = 5;

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [filter, setFilter] = useState("");
    const [spiele, setSpiele] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/liga/spiele/" + liga.id;
        FetchUtil.fetchGet(url,
            json => {
                let tmpSpiele = [];
                for (const spiel of json.spiele) {
                    let tmpSpiel = {...spiel,
                        mannschaftBezHeim: getMannschaftName(json.mannschaften, spiel.mannschaftIdHeim),
                        mannschaftBezGast: getMannschaftName(json.mannschaften, spiel.mannschaftIdGast)};
                    tmpSpiele.push(tmpSpiel);
                }
                setSpiele(tmpSpiele);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const getMannschaftName = (mannschaften, id) => {
        if( !id ) {
            return "-";
        }
        return mannschaften.filter(value => value.id === id)[0].bezeichnung;
    }

    const getTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredSpiele;
        if( filter.length > 0 ) {
            filteredSpiele = spiele.filter(tmp =>
                tmp.mannschaftBezHeim.toUpperCase().includes(filter.toUpperCase()) ||
                tmp.mannschaftBezGast.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredSpiele = spiele;
        }

        let letzterSpieltag = 0;
        filteredSpiele.forEach(spiel => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={spiel.id}>
                <TableCell style={style}>{letzterSpieltag !== spiel.spieltag ? spiel.spieltag : ""}</TableCell>
                <TableCell style={style}>{spiel.mannschaftBezHeim}</TableCell>
                <TableCell style={style}>{spiel.mannschaftBezGast}</TableCell>
            </TableRow>)
            background = !background;
            letzterSpieltag = spiel.spieltag;
        });
        return returnRows;
    }

    const getTable = () => {
        return <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
            <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width:50}}>Spieltag</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Heim</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Gast</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getTableRows()}
                </TableBody>
            </Table>
        </TableContainer>
    }

    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{fontWeight: "bold"}}>
                Spiele zur Liga '{liga.name}'
            </div>
            {liga.status !== "NEW" &&
                <div style={{paddingTop:20}}>Sofern du eine Mannschaft in die Liga einfügen möchtest, setze die Liga auf der Übersicht zurück. Danach kannst du eine Mannschaft zuordnen und die Auslosung erneut starten.</div>
            }
            <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
            </div>
            {getTable()}
        </div>
    }

    return <DHDialog onClose={() => onClose()} title="Ligaspiele" show={true} showCloseButton={true}
                     buttons={[]} comp={getDataArea()}/>
}
