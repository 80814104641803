import React, {useEffect, useState} from 'react'
import DHSelect from "../../general/DHSelect";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";


export default function SelectRahmenspielplanComp({eventOperatorId, refreshCounter, rahmenspielplanId, onError, onChange}) {

    const [rahmenspielplaene, setRahmenspielplaene] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, [refreshCounter]);

    const ladeDaten = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/rahmenspielplaene/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setRahmenspielplaene(json);
            },
            responseNotOk => {
                onError("Fehler: " + responseNotOk.message);
            },
            error => {
                onError("Fehler: " + error.message)
            });
    }

    const getModel = () => {
        let rows = [];
        for (const element of rahmenspielplaene) {
            rows.push({id: element.id, value: element.bezeichnung});
        }
        return rows;
    }

    return <DHSelect width={200} value={rahmenspielplanId} onChange={(id) => onChange(id)} id={"id-rahmenspielplan"} label="Rahmenspielplan" model={getModel()}/>
}
